<template>
  <div class="app-container">
    <div class="main-body">
      <el-row class="call-title">
        <el-col :span="24">
          <i class="lc-tms-iconyunweiguanli icon-cls icon-cls-green" />
          <span class="title">消纳场工单</span>
        </el-col>
      </el-row>
      <div ref="searchBar" class="form-cls">
        <el-form @heightChange="_getTableHeight()" size="small">
          <el-row type="flex" justify="space-between">
            <el-col :span="8">
              <el-form-item label="车牌号码:">
                <el-input
                  v-model.trim="queryParams.plateNumber"
                  clearable
                  type="text"
                  class="input-cls input-width-6"
                  placeholder="请输入内容"
                  @keyup.enter.native="queryList"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="工地工单号:">
                <el-input
                  v-model.trim="queryParams.projectOrderNo"
                  clearable
                  type="text"
                  class="input-cls input-width-6"
                  placeholder="请输入内容"
                  @keyup.enter.native="queryList"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="消纳场工单号:">
                <el-input
                  v-model.trim="queryParams.orderNo"
                  clearable
                  type="text"
                  class="input-cls input-width-6"
                  placeholder="请输入内容"
                  @keyup.enter.native="queryList"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between">
            <el-col :span="8">
              <el-form-item label="消纳场名称:">
                <el-input
                  v-model.trim="queryParams.name"
                  clearable
                  type="text"
                  class="input-cls input-width-6"
                  placeholder="请输入内容"
                  @keyup.enter.native="queryList"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="消纳场经营单位:">
                <el-input
                  v-model.trim="queryParams.businessUnit"
                  clearable
                  type="text"
                  class="input-cls input-width-6"
                  placeholder="请输入内容"
                  @keyup.enter.native="queryList"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="项目名称:">
                <el-input
                  v-model.trim="queryParams.projectName"
                  clearable
                  type="text"
                  class="input-cls input-width-6"
                  placeholder="请输入内容"
                  @keyup.enter.native="queryList"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="6">
              <el-form-item label="土方单位:">
                <el-input
                  v-model.trim="queryParams.companyName"
                  clearable
                  type="text"
                  class="input-cls input-width-6"
                  placeholder="请输入内容"
                  @keyup.enter.native="queryList"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="是否消纳:">
                <el-select class="input-width-6" v-model="queryParams.hasRecycle" clearable>
                  <el-option :value="0" label="未消纳"></el-option>
                  <el-option :value="1" label="已消纳"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="启动时间:">
                <el-date-picker
                  v-model="queryParams.time"
                  type="daterange"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item>
                <el-button plain size="small" type="primary" icon="el-icon-search" @click="queryList"> 查询</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- </search-bar> -->
      </div>
      <div class="main-body">
        <vxe-toolbar ref="xToolbar" custom style="margin-left: 10px; padding-right: 10px">
          <!-- <template #buttons>
            <el-upload :action="uploadUrl" :limit="1" :show-file-list="false" :file-list="[]">
              <el-button plain key="addFile" size="small" type="primary"> 新增导入 </el-button>
            </el-upload>

            <el-upload style="margin-left: 10px" :action="uploadUrl" :limit="1" :show-file-list="false" :file-list="[]">
              <el-button plain key="addUpdata" size="small" type="primary"> 导入更新 </el-button>
            </el-upload>
          </template> -->
          <template #tools>
            <vxe-pager
              align="left"
              placement="top"
              :total="total"
              :current-page.sync="queryParams.currentPage"
              border
              :page-sizes="[100, 120, 150]"
              size="small"
              :page-size.sync="queryParams.pageSize"
              :layouts="['PrevJump', 'PrevPage', 'Jump', 'NextPage', 'NextJump', 'Sizes', 'Total']"
              @page-change="queryList('0')"
            />
          </template>
        </vxe-toolbar>
      </div>
      <el-row class="form-cls button-cls">
        <el-col :span="24">
          <vxe-table
            id="dataListTable"
            ref="table"
            v-loading="listLoading"
            class="sortable-column-demo"
            :export-config="{}"
            :data="dataList"
            fit
            :height="_tableHeight"
            element-loading-text="加载中"
            border
            stripe
            column-key
            resizable
            highlight-current-row
            show-overflow="tooltip"
            :resizable-config="{ minWidth: 50 }"
            :custom-config="{ storage: true }"
            :scroll-y="{ gt: -1 }"
            tooltip-effect="dark"
            size="small"
            style="width: 100%"
          >
            <vxe-table-column field="weight" title="净重" align="center" width="150" />
            <vxe-table-column field="volume" title="方重" align="center" width="150" />
            <vxe-table-column field="orderNo" title="工单号" align="center" width="220" />
            <vxe-table-column field="projectOrderNo" title="工地单号" align="center" width="220" />
            <vxe-table-column field="projectName" title="工地名称" align="center" width="150" />
            <vxe-table-column field="recycleAddress" title="消纳点" align="center" width="150" />
            <vxe-table-column field="carPlate" title="车牌号" align="center" width="150" />
            <vxe-table-column field="fleetName" title="车队名称" align="center" width="150" />
            <vxe-table-column field="enterTime" title="进场时间" align="center" width="150" />
            <vxe-table-column field="remark" title="备注" align="center" width="200" />

            <vxe-table-column align="center" fixed="right" title="操作" width="120">
              <template v-slot="{ row }">
                <span style="color: deepskyblue; cursor: pointer" @click="handleRail(row)">查看轨迹</span>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-col>
      </el-row>

      <el-dialog :visible.sync="dialogVisible" @close="mapClose" :title="dialogTitle" width="60%" :close-on-click-modal="false">
        <el-row>
          <el-col :span="24">
            <!--            <div style="width: 100px; height: 20px" @click="addCount"></div>-->

            <el-form size="small">
              <el-row>
                <el-col :span="3">
                  <el-form-item>
                    <el-button plain size="small" type="primary" @click.stop="getProjectGeofence">查看工地</el-button>
                  </el-form-item>
                </el-col>
                <el-col :span="3">
                  <el-form-item>
                    <el-button plain size="small" type="primary" @click.stop="getAllProjectGeoFences(1)">所有工地</el-button>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-date-picker
                    v-model="trackParam.time"
                    type="datetimerange"
                    format="yyyy-MM-dd HH:mm:ss"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <el-button plain size="small" type="primary" icon="el-icon-search" @click="carTrackDate"> 查询轨迹</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>

            <!--           <el-button v-show="showBtn"  plain size="small" type="primary" @click.stop="getAllRecycleGeofence(1)">延长轨迹</el-button>-->
            <div class="amap-section">
              <el-amap
                :plugin="plugin"
                :center="center"
                :amap-manager="amapManager"
                :zoom="zoom"
                :events="events"
                :resize-enable="true"
                class="map"
              ></el-amap>
            </div>
          </el-col>
        </el-row>
      </el-dialog>
    </div>
    <viewer :options="viewerOptions" :images="viewerImages" @inited="viewerInited" class="viewer" ref="viewer">
      <template #default="scope">
        <img v-for="src in scope.images" :src="src" :key="src" width="1px" height="1px" style="opacity: 0" />
      </template>
    </viewer>
  </div>
</template>

<script>
import { AMapManager } from 'vue-amap'
import CalTableHeight from '@/mixins/calTableHeight'
import {
  aiOrderPageList as queryList,
  centerOrderMap,
  carRecyckeTrackData,
  carTrackData,
  recycleProjectGeofence,
  allProjectGeoFences
} from '@/api/base'

const amapManager = new AMapManager()

let mapStartMarker
let mapEndMarker
let mapPolyline

export default {
  data() {
    return {
      $viewer: null,
      total: 0,
      uploadUrl: '/',
      queryParams: {
        companyName: '',
        plateNumber: '',
        businessUnit: '',
        name: '',
        orderNo: '',
        projectOrderNo: '',
        hasRecycle: '',
        projectName: '',
        currentPage: 1,
        pageSize: 100
      },
      trackParam: {
        time: ''
      },
      dataList: [],
      recycleProjectList: [],
      dataSelectRow: {},
      dataSelectOrderDetail: {},
      listLoading: false,
      dialogVisible: false,
      dialogTitle: '消纳场工单轨迹',
      viewerOptions: {
        zIndex: 5000
      },
      viewerImages: [],
      polygonsData: [], // 存储多边形数据
      polygons: [], // 存储多边形实例
      circlesData: [],
      circles: [], // 存储圆形实例
      // 地图
      map: null,
      showBtn: true,
      lastClickTime: null,
      count: 0,
      plugin: [
        'AMap.OverView', // 地图鹰眼插件
        // 'AMap.ToolBar', //  地图工具条
        'AMap.MapType' //  类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
      ],
      // ------------高德地图参数开始-----------------
      resizeEnable: false, //是否监控地图容器尺寸变化
      zoom: 16, // 设置初始化级别
      mapStyle: 'amap://styles/blue', // 使用的自
      center: [120.236713, 30.206855],
      amapManager,
      events: {
        init: map => {
          console.log('map init ok', map)
          this.map = map
          // this.startTrack()
        }
      }
    }
  },
  mixins: [CalTableHeight],
  computed: {},
  async mounted() {
    this.$nextTick(() => {
      // 手动将表格和工具栏进行关联,否则列配置将失效
      this.$refs.table.connect(this.$refs.xToolbar)
    })
    this.$nextTick(() => {
      this._getTableHeight()
    })
    this.queryList()

    document.addEventListener('click', this.viewerShow, false)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.viewerShow)
  },
  methods: {
    viewerInited(viewer) {
      this.$viewer = viewer
    },
    addCount() {
      console.log('来了老弟')
      let currentTime = new Date().getTime()
      if (!this.lastClickTime) {
        this.lastClickTime = currentTime
      }
      this.lastClickTime = currentTime
      if (this.lastClickTime && currentTime - this.lastClickTime < 1000) {
        this.count++
      } else {
        // 记录本次点击的时间
        this.count = 0
      }
      if (this.count > 10) {
        this.showBtn = true
      }
    },
    viewerShow(e) {
      const { type } = e.target.dataset
      const { beginCarImg1, beginCarImg2, endCarImg1, endCarImg2 } = this.dataSelectOrderDetail
      if (type === 'begin') {
        this.viewerImages = [beginCarImg1, beginCarImg2]
        this.$viewer.show()
      } else if (type === 'enter') {
        this.viewerImages = [endCarImg1, endCarImg2]
        this.$viewer.show()
      }
    },

    getProjectGeofence() {
      let orderNo = this.dataSelectRow.orderNo
      console.log('orderNo =======,', orderNo)
      console.log('this.dataSelectRow =======,', this.dataSelectRow)
      recycleProjectGeofence({ orderNo: orderNo }).then(res => {
        this.polygonsData = []
        this.circlesData = []
        if (res.code == 200) {
          if (!!res.data) {
            let geofences = res.data
            console.log('genfences', geofences)
            this.saveGeoFence(geofences)
          }
        }
      })
    },

    saveGeoFence(geoFences) {
      for (let i = 0; i < geoFences.length; i++) {
        let graphicType = geoFences[i].graphicType
        let polygonRange = geoFences[i].polygonRange
        let assistantPolygonRange = geoFences[i].assistantPolygonRange
        if (graphicType === 0) {
          if (geoFences[i].lon && geoFences[i].lat) {
            let cycleData = {
              name: geoFences[i].name,
              center: new AMap.LngLat(geoFences[i].lon, geoFences[i].lat),
              radius: geoFences[i].radius || 200
            }
            this.circlesData.push(cycleData)
          }
        } else {
          if (!!polygonRange) {
            let path = []
            let initPolygonData = JSON.parse(polygonRange)

            for (let j = 0; j < initPolygonData.length; j++) {
              path.push(new AMap.LngLat(initPolygonData[j].lon, initPolygonData[j].lat))
            }
            this.polygonsData.push({
              path: path
            })
          }
          if (!!assistantPolygonRange) {
            let path = []
            let initPolygonData = JSON.parse(assistantPolygonRange)
            for (let j = 0; j < initPolygonData.length; j++) {
              path.push(new AMap.LngLat(initPolygonData[j].lon, initPolygonData[j].lat))
            }
            this.polygonsData.push({
              path: path,
              name: geoFences[i].name
            })
          }
        }
        console.log('this.circlesData', this.circlesData)
        if (this.circlesData.length > 0) {
          this.addCirclesToMap()
        }
        if (this.polygonsData.length > 0) {
          this.addPolygonsToMap()
        }
      }
    },

    getAllProjectGeoFences(used) {
      allProjectGeoFences({ used: used }).then(res => {
        this.polygonsData = []
        this.circlesData = []
        if (res.code == 200) {
          if (!!res.data) {
            let geofences = res.data
            console.log('geofences', geofences)
            this.saveGeoFence(geofences)
          }
        }
      })
    },

    showInfoWindow(name, position) {
      const infoWindow = new AMap.InfoWindow({
        content: `<div style="text-align: center; padding: 10px;">名称：${name}</div>`,
        offset: new AMap.Pixel(0, -30)
      })

      infoWindow.open(this.map, new AMap.LngLat(position.lng, position.lat))
    },

    clearAll() {
      this.circles.forEach(circle => {
        circle.setMap(null)
      })
      this.circles = []

      this.polygons.forEach(polygon => {
        polygon.setMap(null)
      })
      this.polygons = []

      this.count = 0
      this.showBtn = false
    },
    addCirclesToMap() {
      this.circles.forEach(circle => {
        circle.setMap(null)
      })
      this.circles = []
      this.circlesData.forEach(circleData => {
        const circle = new AMap.Circle({
          center: circleData.center,
          radius: circleData.radius,
          strokeColor: '#ff3363',
          strokeWeight: 6,
          strokeOpacity: 0.2,
          fillOpacity: 0.4,
          fillColor: '#1791fc',
          zIndex: 50
        })
        // 为多边形添加点击事件
        AMap.event.addListener(circle, 'click', () => {
          this.showInfoWindow(circleData.name, circleData.center)
        })
        this.circles.push(circle)
        this.map.add(this.circles)
      })
      this.map.setFitView()
    },
    addPolygonsToMap() {
      this.polygons.forEach(polygon => {
        polygon.setMap(null)
      })
      this.polygons = [] // 清空多边形数组
      this.polygonsData.map(polygonData => {
        const polygon = new AMap.Polygon({
          path: polygonData.path,
          name: polygonData.name,
          strokeColor: '#FF33FF',
          strokeWeight: 6,
          strokeOpacity: 0.2,
          fillOpacity: 0.4,
          fillColor: '#1791fc',
          zIndex: 50
        })
        this.polygons.push(polygon)
        console.log('-----------------polygon--------', polygon)
        // 为多边形添加点击事件
        AMap.event.addListener(polygon, 'click', () => {
          this.showInfoWindow(polygonData.name, polygonData.path[0])
        })
        return polygon
      })
      this.map.add(this.polygons)
      this.map.setFitView()
    },
    queryList() {
      this.listLoading = true
      const { queryParams } = this
      const rangeTime = queryParams.time || ['', '']
      return queryList({
        ...queryParams,
        time: undefined,
        timeRequest: {
          start: rangeTime[0],
          end: rangeTime[1]
        }
      })
        .then(({ data }) => {
          this.dataList = data.records
          this.total = data.total
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    handleRail(row) {
      this.dataSelectRow = row
      this.dialogVisible = true
      this.clearAll()
      carRecyckeTrackData({
        orderNo: row.orderNo
      }).then(({ data }) => {
        this.dataSelectOrderDetail = data
        this.mapClose()
        setTimeout(() => {
          this.startTrack(data)
        }, 0)
      })
    },

    carTrackDate() {
      const rangeTime = this.trackParam.time || ['', '']
      if (!this.trackParam.time) {
        this.$message({
          message: '请选择查询时间',
          type: 'warning'
        })
        return
      }
      // this.clearAll()
      carTrackData({
        carPlate: this.dataSelectRow.carPlate,
        startTime: rangeTime[0],
        endTime: rangeTime[1]
      }).then(({ data }) => {
        this.dataSelectOrderDetail = data
        this.mapClose()
        setTimeout(() => {
          this.startTrack(data)
        }, 0)
      })
    },
    getLineArr() {
      return [
        [116.478935, 39.997761],
        [116.478939, 39.997825],
        [116.478912, 39.998549],
        [116.478912, 39.998549],
        [116.478998, 39.998555],
        [116.478998, 39.998555],
        [116.479282, 39.99856],
        [116.479658, 39.998528],
        [116.480151, 39.998453],
        [116.480784, 39.998302],
        [116.480784, 39.998302],
        [116.481149, 39.998184],
        [116.481573, 39.997997],
        [116.481863, 39.997846],
        [116.482072, 39.997718],
        [116.482362, 39.997718],
        [116.483633, 39.998935],
        [116.48367, 39.998968],
        [116.484648, 39.999861]
      ]
    },
    mapClose() {
      if (mapStartMarker) {
        this.map.remove(mapStartMarker)
      }
      if (mapEndMarker) {
        this.map.remove(mapEndMarker)
      }
      if (mapPolyline) {
        this.map.remove(mapPolyline)
      }
    },
    getMapMarker(lineArr) {
      return {
        startMarker: new AMap.Marker({
          position: new AMap.LngLat(lineArr[0][0], lineArr[0][1]),
          offset: new AMap.Pixel(-13, -30),
          icon: new AMap.Icon({
            size: new AMap.Size(25, 34), // 图标尺寸
            image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png', // 图标的取图地址
            imageSize: new AMap.Size(135, 40), // 图标所用图片大小
            imageOffset: new AMap.Pixel(-9, -3) // 图标取图偏移量
          })
        }),
        endMarker: new AMap.Marker({
          position: new AMap.LngLat(lineArr[lineArr.length - 1][0], lineArr[lineArr.length - 1][1]),
          offset: new AMap.Pixel(-13, -30),
          icon: new AMap.Icon({
            size: new AMap.Size(25, 34),
            image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
            imageSize: new AMap.Size(135, 40),
            imageOffset: new AMap.Pixel(-95, -3)
          })
        })
      }
    },
    startTrack(data) {
      console.log('@@@@', data)
      const _this = this
      const map = amapManager.getMap()
      if (_this.map) {
        _this.map = map
      }
      // 轨迹点
      let lineArr = (data.locationList || []).map(ele => {
        return [ele.longitude, ele.latitude]
      })
      let position

      // lineArr = null
      console.log(lineArr)

      if (lineArr == null) {
        position = [116.478935, 39.997761]
        lineArr = _this.getLineArr()
      } else {
        position = lineArr[0]
      }

      const { startMarker, endMarker } = this.getMapMarker(lineArr)
      startMarker.setLabel({
        offset: new AMap.Pixel(0, 80), //设置文本标注偏移量
        direction: 'center',
        content: ` <div class="map-card">
              <div class="map-card-title">${data.beginCarAddress || '标题'}</div>
              <div class="map-card-image map-card-container">
                <img class="map-card-image" data-type="begin" src="${data.beginCarImg1}" alt="" />
                <img class="map-card-image" data-type="begin" src="${data.beginCarImg2}" alt="" />
              </div>
              <div class="map-card-footer">车牌号码：${data.plateNumber || ''}</div>
              <div class="map-card-footer">开始时间：${data.beginCarTime || ''}</div>
            </div>`
      })

      map.add([startMarker])

      if (data.endCarAddress && data.endCarTime) {
        endMarker.setLabel({
          offset: new AMap.Pixel(0, -80), //设置文本标注偏移量
          direction: 'center',
          content: `
          <div class="map-card">
            <div class="map-card-title">${data.endCarAddress || '标题'}</div>
            <div class="map-card-image  map-card-image-container">
              <img class="map-card-image" data-type="enter" src="${data.endCarImg1}" alt="" />
              <img class="map-card-image" data-type="enter" src="${data.endCarImg2}" alt="" />
            </div>
            <div class="map-card-footer">车牌号码：${data.plateNumber || ''}</div>
            <div class="map-card-footer">到达时间：${data.endCarTime || ''}</div>
          </div>`
        })
        map.add([endMarker])
      }

      // 绘制轨迹
      let polyline = new AMap.Polyline({
        map: map,
        path: lineArr,
        showDir: true,
        strokeColor: '#28F', //线颜色
        strokeWeight: 6, //线宽
        strokeOpacity: 1, //线透明度
        strokeStyle: 'solid' //线样式
      })
      mapStartMarker = startMarker
      mapEndMarker = endMarker
      mapPolyline = polyline
      // 自动适配视图
      map.setFitView()
    }
  }
}
</script>

<style lang="scss">
.amap-info-content {
  padding: 0;
}

.map-card-contennar {
  width: 310px;
  height: 113px;
}

.map-card {
  width: 310px;
  height: 113px;
  padding: 12px;
  background-color: #031d32;
  border: 2px solid #4baaa4;
  color: #fff;
  font-size: 12px;

  &-title {
    background-color: #184551;
    position: relative;
    padding-left: 10px;

    &::before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      height: 100%;
      background-color: #6ae5d3;
    }
  }

  &-image-container {
    min-height: 58px;
  }

  &-image {
    display: flex;
    width: 80px;
    max-width: 143px;
    padding: 2px;
  }

  img {
    max-width: 143px;
    max-height: 143px;
  }
}
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
.input-cls {
  width: 260px;
  font-size: 14px;
  // feat: 搜索栏过多收起
  margin-right: 10px;
}

.icon-cls-green {
  color: #67c23a;
}

.dialog-row {
  margin-top: 10px;
}
</style>

<style rel="stylesheet/scss" lang="scss">
.amap-section {
  height: 600px;
}

.validFieldClass .el-input__inner {
  border-color: #f56c6c;
}

.el-pagination {
  float: right;
}

.sortable-column-demo .vxe-header--row .vxe-header--column.sortable-ghost,
.sortable-column-demo .vxe-header--row .vxe-header--column.sortable-chosen {
  background-color: #dfecfb;
}

.vxe-table--footer .col-blue {
  background: #f2f5f0;
}

/*单号复制样式 开始*/
.copy-content {
  right: 0.1em;
  top: 0.1em;
  float: right !important;
  position: absolute !important;
}

.copy-content:hover {
  background-color: #1482f0;
  float: right !important;
  height: 1.8em;
  width: 1.8em;
}

.write-color {
  border-color: #f0f0f0;
}

/*单号复制样式 结束*/
.input-width-6 {
  width: 60% !important;
}

.input-width-7 {
  width: 70% !important;
}

.input-width-8 {
  width: 80% !important;
}

.input-width-16 {
  width: 300px;
}
</style>
